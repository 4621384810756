<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ item.pname }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white;padding-top: 120px;padding-bottom: 120px;"
    >
      <div class="container ">
        <div class="blogPost row">
          <img
            :src="$baseUploadURL + item.image"
            alt="Image Blog"
            class="img-responsive col-md-6"
          />

          <p>Size : {{ item.size }}ml</p>
          <p>Price : {{ item.price }}€</p>
          <p class=" col-md-6" v-html="$getByLang(item.body)"></p>
          <p>
            <i
              @click="push(item)"
              class="fa fa-cart-plus"
              style="
              color: #fff;font-weight: 400;font-size: 2.765em;"
            ></i>
          </p>
        </div>

        <!-- <div class="card">
          <ul class="list-group list-group-flush">
            <li class="list-group-item">
              <form @submit.prevent="addComment">
                <div class="form-group">
                  <label for="body">{{ $t('comment') }}</label>
                  <textarea
                    class="form-control"
                    id="body"
                    rows="6"
                    v-model="body"
                  ></textarea>
                </div>

                <button type="submit" class="btn btn-primary" :disabled="!body">
                  {{ $t('add') }}
                </button>
              </form>
            </li>
            <li
              class="list-group-item"
              v-for="itm in item.blogcomment"
              :key="itm.id"
            >
              <div class="media">
                <img
                  src="../assets/user.png"
                  style="width: 100px;"
                  class="mr-3"
                />
                <div class="media-body">
                  <br />
                  <br />
                  <p>
                    {{ itm.comment }}
                  </p>
                </div>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      item: {
        name: null,
        image: null,
        body: null,
        createdAt: null,
        id: null,
        blogcomment: [],
      },
      body: null,
    };
  },
  methods: {
    push(item) {
      let itemsList = localStorage.itemsList
        ? JSON.parse(localStorage.itemsList)
        : [];

      let index = itemsList.findIndex((i) => i.id == item.id);

      if (index == -1) {
        itemsList.push({
          ...item,
          quantity: 1,
        });
      } else {
        itemsList[index].quantity = itemsList[index].quantity + 1;
      }
      localStorage.itemsList = JSON.stringify(itemsList);
      this.$eventHub.$emit("addItem", {});
    },
    addComment() {
      if (this.body) {
        this.$http
          .post(`blog/addComment`, {
            user_id: 1,
            blog_id: this.item.id,
            comment: this.body,
          })
          .then(
            (res) => {
              if (res.data) {
                this.item.blogcomment.push(res.data);

                this.body = null;
              }
            },
            (err) => {
              console.log(err);
            }
          );
      }
    },
  },
  created() {
    const id = this.$route.params.id;
    if (id) {
      this.$http.get(`products/${id}`).then(
        (res) => {
          this.item = res.data.data;
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  head: {
    // title: {
    //   inner: 'Offers',
    // },
    // Meta tags
    meta: [
      { name: "application-name", content: "Kleopatra Massage & Beauty" },
      {
        name: "description",
        content: "Kleopatra Massage & Beauty",
      }, // id to replace intead of create element
      // ...
      // Twitter
      { name: "twitter:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        n: "twitter:description",
        c: "Kleopatra Massage & Beauty",
      },
      // ...
      // Google+ / Schema.org
      { itemprop: "name", content: "Kleopatra Massage & Beauty" },
      { itemprop: "description", content: "Kleopatra Massage & Beauty" },
      // ...
      // Facebook / Open Graph
      // { property: 'fb:app_id', content: '123456789' },
      { property: "og:title", content: "Kleopatra Massage & Beauty" },
      // with shorthand
      {
        p: "og:image",
        c: "https://kleopatramassage-beauty.com/image/logo.png",
      },
      // ...
    ],
  },
};
</script>
